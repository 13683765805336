<template>
  <div>
    <b-tooltip
      v-if="isFollowing"
      label="You follow this user!"
      type="is-dark"
      :delay="300"
      :animated="false"
      position="is-bottom"
    >
      <!-- <div class="button is-light" >Following</div> -->
      <b-button
        :class="btnClasses"
        type="is-light is-small  is-outlinedNO"
        icon-pack="fal"
        :expanded="expanded"
        v-on:click="unfollow"
      >
        Following
      </b-button>
    </b-tooltip>
    <!--is-primary is-light is-outlined
      :icon-left="noIcon ? null : 'plus'"
     -->
    <b-button
      v-else
      :class="btnClasses"
      type=" is-light is-small"
      :expanded="expanded"
      icon-pack="fal"
      v-on:click="follow"
    >
      <span v-if="followBack"> Follow back </span>
      <span v-else> Follow </span>
    </b-button>
    <!--
       <div class="button is-primary"  v-on:click="follow" v-else >Follow</div> -->
  </div>
</template>
<script>
export default {
  //name: 'Home',
  components: {
    //bottomBar,
  },
  data() {
    return {
      c: "", //loading char...
      //isFollowing: false, //default
    };
  },
  props: ["id", "expanded", "followBack", "noIcon", "btnClasses", "isBot"],

  computed: {
    isFollowing() {
      // I follow this guy already?
      return this.$store.main.getters.following.filter((id) => id == this.id).length;
      //return this.$store.main.state.followingIds.filter(id => id == this.id).length
    },
  },
  methods: {
    follow(event) {
      this.$store.main.commit("follow", this.id);
    },
    unfollow(event) {
      // alert('unfollow' + this.id );
      this.$store.main.commit("unfollow", this.id);
      //duh
    },
    fetchData(event) {
      //alert('fetchData' + this.id)
      this.loading = true;
      this.c = ""; //remove

      /*API.followUser( this.id ).then(res  => {
            console.log('getFollowersCount', res.data);
            this.c = res.data
            this.loading = false;
          })*/
    },
  },
  watch: {
    id: function (from, to) {
      //necessary to refresh on url changes.
      this.fetchData();
    },
  },
  mounted() {
    /* to be executed when mounted */
    this.fetchData();
  },
};
</script>

<style></style>
