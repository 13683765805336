<template>
  <div>
    <titleBar
      v-if="user && user"
      :title="user.name || user.handle"
      back="auto"
      :showBackArrowDesktop="true"
      :img="user.avatar"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      :inline="false"
      actionButtonStyle="round"
      actionButtonRoundBg="transparent"
      iconPack="fas"
      :actions="mobileActions"
    />

    <main v-if="user && user.handle">
      <header class="profile">
        <div class="header-grid">
          <div class="profile-pic">
            <img :src="user.avatar" style="background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00)" />
          </div>
          <div class="profile-info" style="width: 100%">
            <div class="title row" style="width: 100%">
              <div style="float: right; margin-left: 10px; margin-top: 5px">
                <DotDrop :horizontal="true" :items="dropDownItems" />
              </div>
              <h2>
                {{ user.handle }}
              </h2>
              <span class="verified-icon"></span>

              <button v-if="user && !isOwner" class="primary">Follow</button>

              <followButton :id="user.handle" expanded v-if="user && !isOwner" :isBot="false"> </followButton>

              <!-- 
              <button class="secondary">Message</button>
               -->

              <router-link
                v-if="isOwner || isAdmin"
                :to="'/u/' + user.handle + '/settings'"
                class="is-hidden-touch"
                style="margin-left: 20px"
              >
                <i class="fal fa-pencil" style="color: #aaa"></i>
              </router-link>
            </div>
            <div class="by row">
              <small
                v-if="user.invitedBy && !user.hideInvitedBy"
                style="font-size: 12px; margin-top: -0px; display: block"
              >
                invited by
                <router-link :to="'/u/' + user.invitedBy" style="color: blue">{{ user?.invitedBy }}</router-link>
                {{ timeAgo(user.created) }}
              </small>
              <small v-else>&nbsp;</small>
              <br />

              <div class="onlineNow has-text-success" v-if="user.onlineNow">online now</div>
              <div v-else>Last seen {{ timeAgo(user.lastSeen) }}</div>
            </div>

            <div class="desktop-only">
              <div class="details row">
                <ul>
                  <li>
                    <span>{{ nbBots }}</span> bots
                  </li>
                  <li>
                    <router-link :to="'/u/' + user.handle + '/followers'">
                      <span>{{ nbFollowers }}</span> followers
                    </router-link>
                  </li>
                  <li>
                    <router-link :to="'/u/' + user.handle + '/following'">
                      <span>{{ followingCount }}</span> following
                    </router-link>
                  </li>

                  <li v-if="invitedCount">
                    <router-link :to="'/u/' + user.handle + '/invited'">
                      <span>{{ invitedCount }}</span> invited
                    </router-link>
                  </li>
                </ul>
              </div>
              <div class="description row last">
                <h1>{{ user.handle }}</h1>
                <p>
                  {{ user.bio }}
                  <!--  
                  Everyone has a story to tell. dajsdlk jafjoasdj foidsj foidsjiof jdsopfj osdf osadj fiojsdaiofj
                  iosdafjoids jf odsjfoijdsoifjsdoifj oisd jfo sdjfo sdjfo jsdaoif jsdo tell. dajsdlk jafjoasdj foidsj
                  foidsjiof jdsopfj osdf osadj fiojsdaiofj iosdafjoids jf odsjfoijdsoifjsdoifj oisd jfo sdjfo sdjfo
                  jsdaoif jsdo tell. dajsdlk jafjoasdj foidsj foidsjiof jdsopfj osdf osadj fiojsdaiofj iosdafjoids jf
                  odsjfoijdsoifjsdoifj oisd jfo sdjfo sdjfo jsdaoif jsdofj oids fjos
                  <br />
                  Tag <a>#ShotoniPhone</a> to take part.
                  -->

                  <br />

                  <!-- eo tags-->
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="profile-info mobile-only">
          <div class="description row">
            <h1>{{ user.handle }}</h1>
            <span>
              {{ user.bio }}
              <!---  
              Everyone has a story to tell. fhsdk fldsjfl kdsjlf dlskfjkl dsjflk dsjklf jdklsflkdsfk dskfldksfj l
              kdsjfkl dskf klds f jkls
              <br />
              Tag <a>#ShotoniPhone</a> to take part.
              -->
            </span>
            <!-- vfor tags, linking to search page router link -->
          </div>
        </div>
      </header>

      <!--
      <ul class="stories">
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-1.jpg" />
          </div>
          <div class="title">IDPWD</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-2.jpg" />
          </div>
          <div class="title">🎈🧪</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-3.jpg" />
          </div>
          <div class="title">Diwali</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-4.jpg" />
          </div>
          <div class="title">Masked</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-5.jpg" />
          </div>
          <div class="title">Dark Universe</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-6.jpg" />
          </div>
          <div class="title">📱12 Pro 🎥 Test</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-7.jpg" />
          </div>
          <div class="title">Shot and Edited</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-8.jpg" />
          </div>
          <div class="title">Vertical Cinema</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-9.jpg" />
          </div>
          <div class="title">Hermitage 🎨</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-10.jpg" />
          </div>
          <div class="title">🐌💗</div>
        </li>
        <li class="story-item">
          <div class="image">
            <img src="/img/ig_demo/story-11.jpg" />
          </div>
          <div class="title">💧+💡</div>
        </li>
        <div class="slider-button-next">
          <div class="arrow-right-icon"></div>
        </div>
      </ul>
       -->

      <div class="desktop-only">
        <div class="tabs ig">
          <div class="tab-item active">
            <svg aria-label="BOTS" class="_8-yf5" fill="#262626" height="12" viewBox="0 0 48 48" width="12">
              <path
                clip-rule="evenodd"
                d="M45 1.5H3c-.8 0-1.5.7-1.5 1.5v42c0 .8.7 1.5 1.5 1.5h42c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5zm-40.5 3h11v11h-11v-11zm0 14h11v11h-11v-11zm11 25h-11v-11h11v11zm14 0h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11zm14 28h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11z"
                fill-rule="evenodd"
              ></path>
            </svg>
            <span>BOTS</span>
          </div>
          <div class="tab-item">
            <svg aria-label="Posts" class="_8-yf5" fill="#8e8e8e" height="12" viewBox="0 0 48 48" width="12">
              <path
                d="M41 10c-2.2-2.1-4.8-3.5-10.4-3.5h-3.3L30.5 3c.6-.6.5-1.6-.1-2.1-.6-.6-1.6-.5-2.1.1L24 5.6 19.7 1c-.6-.6-1.5-.6-2.1-.1-.6.6-.7 1.5-.1 2.1l3.2 3.5h-3.3C11.8 6.5 9.2 7.9 7 10c-2.1 2.2-3.5 4.8-3.5 10.4v13.1c0 5.7 1.4 8.3 3.5 10.5 2.2 2.1 4.8 3.5 10.4 3.5h13.1c5.7 0 8.3-1.4 10.5-3.5 2.1-2.2 3.5-4.8 3.5-10.4V20.5c0-5.7-1.4-8.3-3.5-10.5zm.5 23.6c0 5.2-1.3 7-2.6 8.3-1.4 1.3-3.2 2.6-8.4 2.6H17.4c-5.2 0-7-1.3-8.3-2.6-1.3-1.4-2.6-3.2-2.6-8.4v-13c0-5.2 1.3-7 2.6-8.3 1.4-1.3 3.2-2.6 8.4-2.6h13.1c5.2 0 7 1.3 8.3 2.6 1.3 1.4 2.6 3.2 2.6 8.4v13zM34.6 25l-9.1 2.8v-3.7c0-.5-.2-.9-.6-1.2-.4-.3-.9-.4-1.3-.2l-11.1 3.4c-.8.2-1.2 1.1-1 1.9.2.8 1.1 1.2 1.9 1l9.1-2.8v3.7c0 .5.2.9.6 1.2.3.2.6.3.9.3.1 0 .3 0 .4-.1l11.1-3.4c.8-.2 1.2-1.1 1-1.9s-1.1-1.2-1.9-1z"
              ></path>
            </svg>
            <span>INVITED</span>
          </div>
          <div class="tab-item last">
            <svg aria-label="Tagged" class="_8-yf5" fill="#8e8e8e" height="12" viewBox="0 0 48 48" width="12">
              <path
                d="M41.5 5.5H30.4c-.5 0-1-.2-1.4-.6l-4-4c-.6-.6-1.5-.6-2.1 0l-4 4c-.4.4-.9.6-1.4.6h-11c-3.3 0-6 2.7-6 6v30c0 3.3 2.7 6 6 6h35c3.3 0 6-2.7 6-6v-30c0-3.3-2.7-6-6-6zm-29.4 39c-.6 0-1.1-.6-1-1.2.7-3.2 3.5-5.6 6.8-5.6h12c3.4 0 6.2 2.4 6.8 5.6.1.6-.4 1.2-1 1.2H12.1zm32.4-3c0 1.7-1.3 3-3 3h-.6c-.5 0-.9-.4-1-.9-.6-5-4.8-8.9-9.9-8.9H18c-5.1 0-9.4 3.9-9.9 8.9-.1.5-.5.9-1 .9h-.6c-1.7 0-3-1.3-3-3v-30c0-1.7 1.3-3 3-3h11.1c1.3 0 2.6-.5 3.5-1.5L24 4.1 26.9 7c.9.9 2.2 1.5 3.5 1.5h11.1c1.7 0 3 1.3 3 3v30zM24 12.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 16.1c-3.6 0-6.6-2.9-6.6-6.6 0-3.6 2.9-6.6 6.6-6.6s6.6 2.9 6.6 6.6c0 3.6-3 6.6-6.6 6.6z"
              ></path>
            </svg>
            <span>ACTIVITY</span>
          </div>
        </div>
      </div>
      <div class="mobile-tabs mobile-only">
        <ul>
          <li>
            <div>{{ nbBots }}</div>
            bots
          </li>
          <li>
            <router-link :to="'/u/' + user.handle + '/followers'">
              <div>{{ nbFollowers }}</div>
              followers
            </router-link>
          </li>
          <li>
            <router-link :to="'/u/' + user.handle + '/following'">
              <div>{{ followingCount }}</div>
              following
            </router-link>
          </li>
        </ul>
        <div class="actions">
          <svg aria-label="Bots" class="_8-yf5" fill="rgb(0, 149, 246)" height="24" viewBox="0 0 48 48" width="24">
            <path
              clip-rule="evenodd"
              d="M45 1.5H3c-.8 0-1.5.7-1.5 1.5v42c0 .8.7 1.5 1.5 1.5h42c.8 0 1.5-.7 1.5-1.5V3c0-.8-.7-1.5-1.5-1.5zm-40.5 3h11v11h-11v-11zm0 14h11v11h-11v-11zm11 25h-11v-11h11v11zm14 0h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11zm14 28h-11v-11h11v11zm0-14h-11v-11h11v11zm0-14h-11v-11h11v11z"
              fill-rule="evenodd"
            ></path>
          </svg>
          <svg aria-label="Posts" class="_8-yf5" fill="#8e8e8e" height="24" viewBox="0 0 48 48" width="24">
            <path
              d="M41 10c-2.2-2.1-4.8-3.5-10.4-3.5h-3.3L30.5 3c.6-.6.5-1.6-.1-2.1-.6-.6-1.6-.5-2.1.1L24 5.6 19.7 1c-.6-.6-1.5-.6-2.1-.1-.6.6-.7 1.5-.1 2.1l3.2 3.5h-3.3C11.8 6.5 9.2 7.9 7 10c-2.1 2.2-3.5 4.8-3.5 10.4v13.1c0 5.7 1.4 8.3 3.5 10.5 2.2 2.1 4.8 3.5 10.4 3.5h13.1c5.7 0 8.3-1.4 10.5-3.5 2.1-2.2 3.5-4.8 3.5-10.4V20.5c0-5.7-1.4-8.3-3.5-10.5zm.5 23.6c0 5.2-1.3 7-2.6 8.3-1.4 1.3-3.2 2.6-8.4 2.6H17.4c-5.2 0-7-1.3-8.3-2.6-1.3-1.4-2.6-3.2-2.6-8.4v-13c0-5.2 1.3-7 2.6-8.3 1.4-1.3 3.2-2.6 8.4-2.6h13.1c5.2 0 7 1.3 8.3 2.6 1.3 1.4 2.6 3.2 2.6 8.4v13zM34.6 25l-9.1 2.8v-3.7c0-.5-.2-.9-.6-1.2-.4-.3-.9-.4-1.3-.2l-11.1 3.4c-.8.2-1.2 1.1-1 1.9.2.8 1.1 1.2 1.9 1l9.1-2.8v3.7c0 .5.2.9.6 1.2.3.2.6.3.9.3.1 0 .3 0 .4-.1l11.1-3.4c.8-.2 1.2-1.1 1-1.9s-1.1-1.2-1.9-1z"
            ></path>
          </svg>
          <svg aria-label="Tagged" class="_8-yf5" fill="#8e8e8e" height="24" viewBox="0 0 48 48" width="24">
            <path
              d="M41.5 5.5H30.4c-.5 0-1-.2-1.4-.6l-4-4c-.6-.6-1.5-.6-2.1 0l-4 4c-.4.4-.9.6-1.4.6h-11c-3.3 0-6 2.7-6 6v30c0 3.3 2.7 6 6 6h35c3.3 0 6-2.7 6-6v-30c0-3.3-2.7-6-6-6zm-29.4 39c-.6 0-1.1-.6-1-1.2.7-3.2 3.5-5.6 6.8-5.6h12c3.4 0 6.2 2.4 6.8 5.6.1.6-.4 1.2-1 1.2H12.1zm32.4-3c0 1.7-1.3 3-3 3h-.6c-.5 0-.9-.4-1-.9-.6-5-4.8-8.9-9.9-8.9H18c-5.1 0-9.4 3.9-9.9 8.9-.1.5-.5.9-1 .9h-.6c-1.7 0-3-1.3-3-3v-30c0-1.7 1.3-3 3-3h11.1c1.3 0 2.6-.5 3.5-1.5L24 4.1 26.9 7c.9.9 2.2 1.5 3.5 1.5h11.1c1.7 0 3 1.3 3 3v30zM24 12.5c-5.3 0-9.6 4.3-9.6 9.6s4.3 9.6 9.6 9.6 9.6-4.3 9.6-9.6-4.3-9.6-9.6-9.6zm0 16.1c-3.6 0-6.6-2.9-6.6-6.6 0-3.6 2.9-6.6 6.6-6.6s6.6 2.9 6.6 6.6c0 3.6-3 6.6-6.6 6.6z"
            ></path>
          </svg>
        </div>
      </div>

      <bot-list :bots="bots" :includeLinks="true" :page-size="200"></bot-list>

      <!-- 
      <div class="gallery">
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-1.jpg" />
          <span class="media-icon"></span>
        </div>

        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-2.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-3.jpg" />
          <span class="media-icon"></span>
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-4.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-5.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-6.jpg" />
          <span class="media-icon"></span>
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-7.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-8.jpg" />
          <span class="media-icon"></span>
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-9.jpg" />
        </div>

        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-2.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-3.jpg" />
          <span class="media-icon"></span>
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-4.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-5.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-6.jpg" />
          <span class="media-icon"></span>
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-7.jpg" />
        </div>
        <div class="gallery-item">
          <img src="/img/ig_demo/gallery-8.jpg" />
          <span class="media-icon"></span>
        </div>
      </div> -->
    </main>

    <!--        -->

    <jsonDebug :data="bots" label="bots" />
    <jsonDebug :data="user" label="user" />
  </div>
</template>

<script>
// SEE CSS + LAYOUT:
// https://codesandbox.io/s/d2nei

import BotList from "@/components/BotList.vue";
import DotDrop from "@/components/e/DotDrop.vue";

import { elipsis, timeAgo, prettyMs } from "@/common/utils";
import followButton from "@/components/e/followButton.vue";

export default {
  components: {
    BotList,
    followButton,
    DotDrop,
  },
  metaInfo() {
    return {
      title: this.title,
      //  desc: this.user?.bio,
    };
  },
  data() {
    return {
      user: {},
      bots: [],
      invitedCount: null,
      followingCount: 0,
      followersCount: 0,
      loadingFollowerCount: true,
      loadingInvitedCount: true,
      //dummy
      username: "johndoe",
      bio: "Just a regular guy",
      profilePic: "https://placekitten.com/800/600",
      tabs: ["Photos", "Videos"],
      activeTab: 0,
      photos: [
        "https://placekitten.com/800/600",
        "https://placekitten.com/600/800",
        "https://placekitten.com/800/800",
        "https://placekitten.com/600/600",
        "https://placekitten.com/400/600",
        "https://placekitten.com/600/400",
      ],
      videos: ["https://www.w3schools.com/html/mov_bbb.mp4", "https://www.w3schools.com/html/movie.mp4"],
      posts: [
        {
          title: "Cute kitten",
          username: "johndoe",
          avatar: "https://placekitten.com/48/48",
          image: "https://placekitten.com/600/800",
          caption: "Aren't kittens just the best?",
        },
        {
          title: "Another cute kitten",
          username: "johndoe",
          avatar: "https://placekitten.com/48/48",
          image: "https://placekitten.com/800/600",
          caption: "Can't get enough of these little guys!",
        },
        {
          title: "Kitten overload",
          username: "johndoe",
          avatar: "https://placekitten.com/48/48",
          image: "https://placekitten.com/600/600",
          caption: "Okay, maybe I need to calm down with the kitten pics...",
        },
      ],
    };
  },
  mounted() {
    this.loadProfile(0);
  },
  watch: {
    $route(to, from) {
      this.loadProfile(0);
    },
  },
  computed: {
    title() {
      return this.user.handle;
    },
    nbFollowers() {
      //one digit foamt.
      // TODO: 25.1m
      return this.followersCount;
      return this.bot.followers?.length || this.bot.nbFollowers || 0;
    },
    mobileActions: function () {
      if (this.isOwner) {
        return [
          {
            icon: "pencil",
            label: "profile",
            to: "/u/" + this.user.handle + "/settings/profile",
          },
          {
            icon: "cog",
            label: "Manage",
            to: "/u/" + this.user.handle + "/settings",
          },
          /*
          {
            icon: "mdi-comment",
            label: "Comment",
            action: () => {
              alert("Comment");
            },
          },
          {
            icon: "mdi-share",
            label: "Share",
            action: () => {
              alert("Share");
            },
          },*/
        ];
      } else {
        return [
          {
            icon: "flag",
            label: "Manage",
            to: "/report-problem/user/" + this.user.handle + "",
          },
        ];
      }
      return [
        {
          icon: "cog",
          label: "Manage",
          to: "/" + this.bot.handle + "/manage",
        },
        {
          icon: "mdi-comment",
          label: "Comment",
          action: () => {
            alert("Comment");
          },
        },
        {
          icon: "mdi-share",
          label: "Share",
          action: () => {
            alert("Share");
          },
        },
      ];
    },
    isOwner() {
      var currentUser = this.$store.main.state.userId;
      if (!currentUser) return false;
      if (!this.user) return false;
      return this.user.handle == currentUser;
    },
    isAdmin() {
      return this.$store.main.getters.isAdmin;
    },
    nbBots() {
      return this.bots?.length;
    },
    dropDownItems() {
      var u = this.user;
      if (!u) return [];

      return [
        {
          icon: "far fa-pencil",
          t: "Edit Profile",
          to: "/u/" + this.user.handle + "/settings/profile",
          hidden: !this.isOwner,
        },
        {
          icon: "far fa-cog",
          t: "Settings",
          to: "/u/" + this.user.handle + "/settings",
          hidden: !this.isOwner,
        },

        {
          t: "  " + this.invitedCount + " members Invited",
          icon: "far fa-user-friends",
          to: this.user.url + "/invited",
          hidden: !this.invitedCount,
        },
        {
          t: "Activity  ",
          icon: "far fa-history",
          to: this.user.url + "/activity",
        },
        /*
        {
          t: "View profile",
          visible: !this.isOwner,
          icon: "far fa-user",
          to: this.authorUrl,
        },
        {
          t: "Comments",
          icon: "far fa-comments",
          to: this.item.url,
          hidden: false,
        },
        {
          t: "Edit...",
          icon: "far fa-edit",
          hidden: !this.isOwner,
          to: this.item.url + "/edit",
        },
        {
          t: "Delete this post...",
          icon: "far fa-trash",
          hidden: !this.isOwner,
          click: () => {
            // alert(324235);
            window.API.editBotPost(this.item.bot, this.item.id, { deleted: true }).then((res) => {
              this.$buefy.snackbar.open({
                message: "Post deleted",
                type: "is-warning",
                position: "is-bottom",
                actionText: "Close",
                indefinite: false,
              });
              this.item = null;
            });
          },
        },*/

        /*
        {
          t: "Email details...",
          icon: "far fa-info",
          click: () => {
            this.showMeta = !this.showMeta;
          },
        },
        {
          t: "Copy link",
          icon: "far fa-link",
          click: () => {
            this.copyLink(item);
          },
        },

        { t: "Open...", icon: "far fa-eye", to: this.getMsgDetailPath(item._id) },

        { t: "Gmail Fwd ", icon: "far fa-envelope", href: this.getMailtoHref(item, "fw") },
        { t: "Gmail Re ", icon: "far fa-envelope", href: this.getMailtoHref(item) },

        {
          t: "Info ",
          icon: "far fa-info-circle",

          click: this.toggleMeta,
        },
        {
          t: "Summarize  ",
          icon: "far fa-info-magic",

          click: this.getEmailSummary,
        },

        {
          t: this.format == "html " ? "Plain text" : "HTML",
          icon: "far fa-" + (this.format == "html " ? "file-alt" : "code"),

          click: () => {
            this.format = this.format == "html" ? "text" : "html";
          },
        },*/
      ];
    },
  },
  methods: {
    timeAgo: function (date) {
      return timeAgo(date);
    },
    prettyMs: function (date) {
      return prettyMs(date);
    },
    async fetchBots() {
      this.loadingBots = true;
      try {
        //   const response = await fetch("/api/bots");
        const data = await window.API.getUserBots(this.$route.params.userId);
        this.bots = data;
        this.loadingBots = false;
      } catch (error) {
        console.error(error);
      }
    },
    checkIfAllCallsAreCompleted() {
      if (!this.loading && !this.loadingBots && !this.loadingFollowingCount && !this.loadingFollowerCount) {
        window.prerenderReady = true;
      }
    },
    loadProfile(index) {
      var id = this.$route.params.userId;
      this.loading = true;
      this.loadingFollowingCount = true;
      this.fetchBots();
      window.API.userProfile(id).then((b) => {
        this.checkIfAllCallsAreCompleted();
        this.user = b;
        this.loading = false;
        // console.log("PROJJ!", proj, proj.id);
      });

      window.API.getUserFollowingCount(id).then((b) => {
        this.checkIfAllCallsAreCompleted();
        this.followingCount = b;
        this.loadingFollowingCount = false;
        // console.log("PROJJ!", proj, proj.id);
      });

      //counts
      this.loadingFollowerCount = true;
      window.API.getUserFollowersCount(id).then((res) => {
        this.followersCount = res;
        this.loadingFollowerCount = false;
        this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });

      this.loadingInvitedCount = true;
      window.API.getUserInvitedCount(id).then((res) => {
        this.invitedCount = res;
        this.loadingInvitedCount = false;
        //   this.checkIfAllCallsAreCompleted();
        // console.log("PROJJ!", proj, proj.id);
      });
    },
  },
};
</script>

<style></style>
